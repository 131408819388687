import LoadingText from 'assets/img/loading.svg';

import twitter from 'assets/img/twitter.svg';
import discord from 'assets/img/discord.svg';
import telegram from 'assets/img/telegram.svg';
import github from 'assets/img/github.svg';
import medium from 'assets/img/medium.svg';
import youtube from 'assets/img/youtube.svg';
import HeaderLogo from 'assets/img/header_logo.svg';
import mobileMenu from 'assets/img/header_mobile_menu.svg';
import mobileMenuActive from 'assets/img/header_mobile_menu_clicked.svg';

import Logo from 'assets/img/logo.svg';
import mobileLogo from 'assets/img/footer_logo_mobile.svg';

import sphere from 'assets/img/sphere.gif';
import coin from 'assets/img/coin.gif';

import falcon from 'assets/img/falcon-join.svg';
import falconHover from 'assets/img/falcon-join-hover.svg';
import falconActive from 'assets/img/falcon-join-active.svg';
import falconIcon1 from 'assets/img/falcon_join_1.svg';
import falconIcon2 from 'assets/img/falcon_join_2.svg';
import falconIcon3 from 'assets/img/falcon_join_3.svg';
import falconIconHover1 from 'assets/img/falcon_join_hover_1.svg';
import falconIconHover2 from 'assets/img/falcon_join_hover_2.svg';
import falconIconHover3 from 'assets/img/falcon_join_hover_3.svg';

import joinVideo from 'assets/img/join_button.svg';

import falconLogo from 'assets/img/falcon_logo.svg';
import arrow from 'assets/img/project_arrow.svg';
import metalLogo from 'assets/img/project/logo_metalheads.svg';
import metalBg from 'assets/img/project/bg_metalheads.svg';
import metalBgHover from 'assets/img/project/bg_metalheads_hover.svg';
import gigabariansLogo from 'assets/img/project/logo_gigabarians.svg';
import gigabariansBg from 'assets/img/project/bg_gigabarians.svg';
import gigabariansBgHover from 'assets/img/project/bg_gigabarians_hover.svg';
import konbiniLogo from 'assets/img/project/logo_konbini.svg';
import konbiniBg from 'assets/img/project/bg_konbini.svg';
import konbiniBgHover from 'assets/img/project/bg_konbini_hover.svg';
import tomodachiLogo from 'assets/img/project/logo_tomodachi.svg';
import tomodachiBg from 'assets/img/project/bg_tomodachi.svg';
import tomodachiBgHover from 'assets/img/project/bg_tomodachi_hover.svg';

import LeftArrow from 'assets/img/tokenomics/token_arrow_left.png';
import RightArrow from 'assets/img/tokenomics/token_arrow_right.png';

import Token from 'assets/img/tokenomics/token.png';
import TokenTransparent from 'assets/img/tokenomics/logo_transparent.png';

import TokenLeft from 'assets/img/tokenomics/token_left.png';
import TokenLeftTablet from 'assets/img/tokenomics/token_left_tablet.png';
import TokenLeftMobile from 'assets/img/tokenomics/token_left_mobile.png';
import TokenThin from 'assets/img/tokenomics/token_thin.png';
import ChainLogo from 'assets/img/tokenomics/chain_logo.png';

import TokenRight from 'assets/img/tokenomics/token_right.png';
import TokenRightTablet from 'assets/img/tokenomics/token_right_tablet.png';
import TokenRightMobile from 'assets/img/tokenomics/token_right_mobile.png';
import BarImage from 'assets/img/tokenomics/bar_image.png';

import TokenBottom from 'assets/img/tokenomics/token_bottom.png';
import TokenMiddleTablet from 'assets/img/tokenomics/token_middle_tablet.png';
import TokenBottomMobile from 'assets/img/tokenomics/token_bottom_mobile.png';
import DropdownArrow from 'assets/img/tokenomics/dropdown_arrow.png';

import TeamSteve from 'assets/img/team/team_steve_zoomed.png';
import TeamAdam from 'assets/img/team/team_adam_zoomed.png';
import TeamPaul from 'assets/img/team/team_paul.png';
import TeamWillson from 'assets/img/team/team_willson_zoomed.png';

import RoadmapPath from 'assets/img/roadmap/roadmap_path.png';
import RoadmapPathTablet from 'assets/img/roadmap/roadmap_path_tablet.png';
import RoadmapPathMobile from 'assets/img/roadmap/roadmap_path_mobile.png';

const PRELOAD_IMAGES: string[] = [

    //Loading overlay
    LoadingText,

    // Header
    twitter,
    discord,
    telegram,
    github,
    medium,
    youtube,
    HeaderLogo,
    mobileMenu,
    mobileMenuActive,
    
    // Footer
    Logo,
    mobileLogo,

    // Welcome section
    sphere,
    coin,

    // Join section
    falcon,
    falconHover,
    falconActive,
    falconIcon1,
    falconIcon2,
    falconIcon3,
    falconIconHover1,
    falconIconHover2,
    falconIconHover3,

    // About us section
    joinVideo,

    // Project section
    falconLogo,
    arrow,
    metalLogo,
    metalBg,
    metalBgHover,
    gigabariansLogo,
    gigabariansBg,
    gigabariansBgHover,
    konbiniLogo,
    konbiniBg,
    konbiniBgHover,
    tomodachiLogo,
    tomodachiBg,
    tomodachiBgHover,

    // Tokenomics section - main page
    LeftArrow,
    RightArrow,

    // Tokenomics section - Explore slide
    Token,
    TokenTransparent,

    // Tokenomics section - Chain&Supply slide
    TokenLeft,
    TokenLeftTablet,
    TokenLeftMobile,
    TokenThin,
    ChainLogo,

    // Tokenomics section - Event&Sales slide
    TokenRight,
    TokenRightTablet,
    TokenRightMobile,
    BarImage,

    // Tokenomics section - Token distribution slide
    TokenBottom,
    TokenMiddleTablet,
    TokenBottomMobile,
    DropdownArrow,

    // Investor section

    // Team section
    TeamSteve,
    TeamAdam,
    TeamPaul,
    TeamWillson,

    // Roadmap section
    RoadmapPath,
    RoadmapPathTablet,
    RoadmapPathMobile,
]

export { PRELOAD_IMAGES };
