import Styled from 'styled-components';

const LoadingOverlayContainer = Styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: colum
    justify-content: center;
    align-items: center;
    background: #343037;
    z-index: 9999;
    overflow: hidden;

    .loading-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: grid;
        align-items: center;
        text-align: center;

        .logo-container {
            min-height: 100px;
        }
    }

    .color {
        position: absolute;
        height: 45vw;
        width: 45vw;
        left: -35%;
        top: 40%;
        border-radius: 50%;
        transform: translate(35%, -40%);
        background: linear-gradient(180deg, #3313C7 0%, #CA63C7 51.56%, #EFCB89 100%);
        opacity: 0.6;
        filter: blur(350px);
    }

    @media screen and (min-width: 768px) {
        .loading-wrapper {
            .logo-container {
                min-height: 125px;
            }
        }
    }
`;

const LoadingText = Styled.div`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    color: #FFFFFF;
`;


export { LoadingOverlayContainer, LoadingText };
