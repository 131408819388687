import { combineReducers } from 'redux';
import CommonReducer from './common/reducer';

const rootReducers = combineReducers({
    common: CommonReducer,
});

export type RootState = ReturnType<typeof rootReducers>

export default rootReducers;
