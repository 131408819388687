/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, ReactNode } from 'react';

type PropTypes = {
    children: ReactNode;
};

export const MouseContext = createContext({
    cursorType: '',
    cursorChangeHandler: (val: string) => {},
});

const MouseContextProvider = (props: PropTypes): JSX.Element => {
    const [cursorType, setCursorType] = useState('');

    const cursorChangeHandler = (cursorType: string) => {
        setCursorType(cursorType);
    };

    return (
        <MouseContext.Provider
            value={{
                cursorType: cursorType,
                cursorChangeHandler: cursorChangeHandler,
            }}
        >
            {props.children}
        </MouseContext.Provider>
    );
};

export default MouseContextProvider;
