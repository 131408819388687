import React, { useState, useEffect } from 'react';
import { LoadingOverlayContainer, LoadingText } from './style';
import loading from 'assets/img/loading.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducers';
import commonActions from 'redux/common/actions';

const LoadingOverlay = (): JSX.Element => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [doneMinLoadingTime, setDoneMinLoadingTime] = useState(false);
    const [progress, setProgress] = useState(0);

    const dispatch = useDispatch();
    const resetLoadedAssetsCount = () => dispatch(commonActions.resetLoadedAssetsCount());

    const totalAssetsCount = useSelector(({ common }: RootState) => common.totalAssetsCount);
    const loadedAssetsCount = useSelector(({ common }: RootState) => common.loadedAssetsCount);

    // Handle the loading overlay based on assets loaded
    useEffect(() => {
        if (isCompleted) return;

        setProgress((loadedAssetsCount / totalAssetsCount) * 100);

        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';

        if (loadedAssetsCount >= totalAssetsCount && doneMinLoadingTime) {
            resetLoadedAssetsCount();
            setIsCompleted(true);
            document.body.style.overflowY = 'auto';
            document.body.style.height = 'auto';
            return;
        }
    }, [totalAssetsCount, loadedAssetsCount, doneMinLoadingTime]);

    // Show loading for minimum 3s
    useEffect(() => {
        setTimeout(() => {
            setDoneMinLoadingTime(true);
        }, 3000);
    }, [totalAssetsCount]);

    // Once completed, destroy the loading overlay

    const LoadingLogo = () => (
        <svg width="46%" height="100%" viewBox="0 0 4709 3474" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="left-to-right" x1="65%" y1="100%" x2="0%" y2="0%" gradientUnits="userSpaceOnUse">
                    <stop offset={`${progress >= 33 ? 0 : progress}%`} stopColor="#EFCB89"></stop>
                    <stop offset={`${progress >= 66 ? 50 : progress}%`} stopColor="#CA63C7"></stop>
                    <stop offset={`${progress >= 99 ? 100 : progress}%`} stopColor="#3614C7"></stop>
                    <stop offset="0" stopColor="#ffffff"></stop>
                </linearGradient>
            </defs>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1214.72C0 489.891 1948.38 78.1131 2381.35 24.0443C2814.32 -30.0245 3409.66 23.9914 3409.66 23.9914L4708.58 186.356V727.577C4708.58 727.577 3842.64 565.212 3409.66 511.09C3355.18 504.279 3304.98 497.849 3258.31 491.87C2934.11 450.34 2780.27 430.633 2543.72 456.969C2375.14 475.735 2326.31 480.084 2278.11 488.195C2248.93 493.104 2219.99 499.392 2164.87 511.09C2077.72 529.582 2064.99 533.099 2052.07 535.723C2042.68 537.631 2033.19 539.066 1994.92 545.445C1904.12 560.577 1579.9 655.993 1465.06 701.832C1648.08 778.1 1793.26 796.214 1839.86 785.826C1880.75 775.183 1985.76 722.504 2096.84 666.781L2096.84 666.781C2280.09 574.858 2479.83 474.654 2435.48 541.224C2428.39 551.878 2421.69 562.384 2415.39 572.76C2393.03 786.012 4483.38 1976.91 4636.33 1980.7C4793.62 1984.6 3976.3 2360.64 3976.3 2360.64C3763.36 2225.5 3531.9 2065.41 3296.69 1902.72L3296.69 1902.71C2997.91 1696.06 2693.07 1485.2 2412.42 1315.98C2436.46 1414.34 2462.54 1526.97 2486.21 1659.7C2594.56 2267.2 2861.03 2866.44 3065.68 3070.36L2105.65 3473.03C2221.5 1865.8 2112.63 1096.23 880.532 919.163C879.939 919.172 879.353 919.176 878.773 919.176C696.958 914.126 494.648 929.042 378.781 974.731C219.013 1026.07 138.319 1086.44 0 1214.72Z"
                fill="url(#left-to-right)"
            />
        </svg>
    );

    if (isCompleted) {
        return <></>;
    }

    return (
        <LoadingOverlayContainer>
            <div className="color" />
            <div className="loading-wrapper">
                <div className="logo-container">
                    <LoadingLogo />
                    
                </div>
                <LoadingText><img src={loading} alt="Loading" /></LoadingText>
            </div>
        </LoadingOverlayContainer>
    );
};

export default LoadingOverlay;
