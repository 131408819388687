import Styled from 'styled-components';

const Cursor = Styled.div`
    position: fixed;
    border: 1px solid #CA63C7;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 12px;
    margin-left: -16px;
    margin-top: -16px;
    z-index: 99999;
    will-change: width, height, transform, border;
    pointer-events: none;
    display: none;
    
    .cursor-inner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #CA63C7;
        width: 6px;
        height: 6px;
        border-radius: 100%;
    }

    &.hovered {
        opacity: 0.5;
        background: #CA63C7;
        width: 40px;
        height: 40px;

        .cursor-inner {
            display: none;
        }
    }

    @media screen and (min-width: 1200px) {
        display: block;
    }
`;

export { Cursor };
