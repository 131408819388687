import React, { useEffect, useRef, useContext } from 'react';
import { Cursor } from './style';
import useMousePosition from 'hooks/useMousePosition';
import { MouseContext } from 'context/mouseContext';

const CustomAppCursor = (): JSX.Element => {
    const { cursorType } = useContext(MouseContext);

    const cursorRef = useRef<any>();

    const { x, y } = useMousePosition();

    useEffect(() => {
        if (cursorRef && (x || y)) {
            cursorRef.current.style.left = `${x}px`;
            cursorRef.current.style.top = `${y}px`;
        }
    }, [x, y]);

    return (
        <Cursor ref={cursorRef} className={`custom-cursor ${cursorType}`}>
            <div className="cursor-inner"></div>
        </Cursor>
    );
};

export default CustomAppCursor;
